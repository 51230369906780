import React, { useEffect, useState, useContext  } from 'react';
import searchPic from '../assets/search.jpg'
import '../App.css';
import { appContext } from '../App'
import Menu from '../components/Menu'
import pdfIcon from '../assets/pdf.png'
import CustomSelect from '../components/CustomSelect'






function SearchScreen(props){

  const { history } = props

    //appContext
    const [ appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
      popupMessage, setPopupMessage, dataList, jsonTextList, setJsonTextList, searchResultList, setSearchResultList
      ] = useContext(appContext)

    const options=[
      {label:'szerkezet'}, {label:'teszt'}, {label:'szél'}, {label:'turbina'}, {label:'erőmű'}, {label:'generátor'}, {label:'acél'}, {label:'fém'},
      {label:'ferro'}, {label:'lágy'}, {label:'mágnesek'}, {label:'szélkerék'}, {label:'szigetelő'}, {label:'szolenoid'}, {label:'deformáció'}, {label:'indukció'},
      {label:'composite'}, {label:'thermoplastic'}, {label:'mechanical'}, {label:'testing'}, {label:'wind'}, {label:'turbine'}, {label:'power'}, {label:'generation'},
      {label:'metal'}, {label:'steel'}, {label:'temperature'}, {label:'diffusion'}, {label:'magnet'}, {label:'magnetic'}, {label:'mild'}, {label:'the'}
    ]
    
    let foundList = []

    useEffect(() => {
         
    }, [dataList, foundList])


    const handleDropdown = (value) => { search(value) }

    const search = (dropdownValue) => {
        setSearchResultList([])
        let searchWord = dropdownValue.label.toString()
        foundList.lentgh = 0
        Object.keys(dataList).map(id =>{
          if(dataList[id].jsonname.toString().toLowerCase().includes(searchWord)){
            setSearchResultList(searchResultList => [...searchResultList, dataList[id]])
          }
        })
        
    }

    const clickPdf = (pdfName) => { window.open('https://homalyzona.herokuapp.com/sybrilloknow/showpdf?filename=/jphpdf/' + pdfName, '_blank') }

    return(
        <div className = 'appContainer' >
          
            <div className = 'menuContainer' >
              <Menu history = {history}></Menu>
            </div>
            <div className = 'search'>
              <h1>Keresőszó-találati lista</h1>
              <div className = 'contentTable'>
            {Object.keys(searchResultList).map(id =>{
              return <div key = {id} >
                <div className = 'contentRow' key = {id}>
                    <div id = 'contentPic'><img id = 'contentPicture' src = {pdfIcon}></img></div>
                    <div id = 'contentTitle'><h2 onClick={(e) => clickPdf(searchResultList[id].pdfname)} >{searchResultList[id].pdfname}</h2></div>
                </div>
              </div>
              })}
          </div>
            </div>
            <div className = 'searchDropdown' >
              <CustomSelect onChange={handleDropdown} options={options}/>
            </div>
            <div className = 'imageContainer' >
                <img className = 'image'  src={searchPic} alt="home1"></img>
            </div>
        </div>
    )
}

export default SearchScreen