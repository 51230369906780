import React, { useEffect, useState, useContext  } from 'react';
import contentPic from '../assets/content.jpg'
import '../App.css';
import { appContext } from '../App'
import Menu from '../components/Menu'
import pdfIcon from '../assets/pdf.png'


function ContentScreen(props){

  const { history } = props

    //appContext
    const [ appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
      popupMessage, setPopupMessage, dataList, jsonTextList, setJsonTextList, searchResultList, setSearchResultList
      ] = useContext(appContext)

      useEffect(() => {
         
      }, [dataList])

    const clickPdf = (pdfName) => { window.open('https://homalyzona.herokuapp.com/jphdata/showpdf?filename=/jphpdf/' + pdfName, '_blank') }
    //const clickPdf = (pdfName) => { window.open('http://localhost:3000/jphdata/showpdf?filename=/jphpdf/' + pdfName, '_blank') }

    return(
        <div className = 'appContainer' >
            <div className = 'menuContainer' >
            <Menu history = {history}></Menu>
            </div>
            <div className = 'content'>
            <h1>Tudásbázis rekordok</h1>
          <div className = 'contentTable'>
            {Object.keys(dataList).map(id =>{
              return <div key = {id} >
                <div className = 'contentRow' key = {id}>
                    <div id = 'contentPic'><img id = 'contentPicture' src = {pdfIcon}></img></div>
                    <div id = 'contentTitle'><h2 onClick={(e) => clickPdf(dataList[id].pdfname)} >{dataList[id].pdfname}</h2></div>
                </div>
              </div>
              })}
          </div>
        </div>
            <div className = 'imageContainer' >
                <img className = 'image'  src={contentPic} alt="home1"></img>
            </div>
        </div>
    )
}

export default ContentScreen