import React, { useEffect, useState, useContext, Fragment  } from 'react';
import adminPic from '../assets/admin.jpg'
import { NavLink } from 'react-router-dom';
import '../App.css';
import { appContext } from '../App'
import fire, { storage, auth } from '../database/Firebase'
import { updateContact } from '../database/DatabaseOp'
import { sendEmail } from '../service/EmailOp'
import ReactS3 from 'react-s3'
import Menu from '../components/Menu'



let buttonStyle
let refWithId
let emailObject = {}


function AdminScreen(props){

  const { history } = props

  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
    popupMessage, setPopupMessage, pdfList, setPdfList, jsonList, setJsonList
  ] = useContext(appContext)

  const s3Config = {
    bucketName: 'jphtrading',
    region: 'eu-central-1',
    accessKeyId: 'AKIAIZL77QN2KSHTQL2A',
    secretAccessKey: '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',
  }

  useEffect(() => {

  }, [])

  const handleApply = (id, email) => {
    console.log('admin apply email ', email)
    setUpdaterId(id)
    let userUpdate = {}
      userUpdate.user_id = id
      userUpdate.role = 'Jóváhagyott'
      refWithId = fire.database().ref(`contacts/${id}`)
    updateContact(refWithId, userUpdate)
    //jóváhagyó email usernek
      emailObject.email = email
      emailObject.title = 'Jóváhagyott regisztráció a jphtrading.hu oldalon'
      emailObject.text = 'Az adminisztrátor jóváhagyta a regisztrációját a jphtrading.hu oldalon. Bejelentkezés után minden menüpontot el fog érni.'
    handleEmail(emailObject)
    }

  const handleEmail = (emailObject) => {
    console.log('register handleemail ', emailObject)
    sendEmail(emailObject)
    .then(data => console.log('SENDEMAIL resolved ', data))
    .catch(err => console.log('SENDEMAIL rejected ', err))
  }

   return(
      <div className = 'appContainer'>
        <div className = 'admin'>
          <div className = 'admiTable'>
            <div className = 'adminRow'> 
                <div id = 'adminUser'>Felhasználó</div>
                <div id = 'adminRole'>Szerep</div>
            </div>
            {Object.keys(appContactList).map(id =>{
              {appContactList[id].role == 'regisztrált' ? buttonStyle = 'buttonVisible' : buttonStyle = 'buttonInvisible'}
              return <div key = {id} className = 'adminRow'>
                <div className = 'adminRow' key = {id}>
                  <div id = 'adminUser'>{appContactList[id].email}</div>
                  <div id = 'adminRole'>{appContactList[id].role}</div>
                  <div className = {buttonStyle}>{<button className = 'btn btn-warning btn-lg' onClick={(e) => handleApply(id, appContactList[id].email)}>jóváhagyás</button>}</div>
                </div>
              </div>
              })}
          </div>
        </div>
        <div className = 'menuContainer' >
        <Menu history = {history}></Menu>
        </div>
        <div className = 'imageContainer' >
          <img className = 'image'  src={adminPic} alt="home1"></img>
        </div>
      </div>
    )
}

export default AdminScreen