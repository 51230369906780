import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import AdminScreen from './screens/AdminScreen';
import UploadScreen from './screens/UploadScreen';
import ContentScreen from './screens/ContentScreen';
import SearchScreen from './screens/SearchScreen';
import fire from './database/Firebase'
import "firebase/auth"
import { loadContacts, dataRead } from './database/DatabaseOp'
import Popup from './service/Popup'
import { motion, AnimatePresence } from 'framer-motion'
import { parser } from './components/JsonParser'


export const appContext = React.createContext([{}, () => {}])

let ref = fire.database().ref('contacts')
let fillChecker = false


const App = (props) => {

  const [ appContactList, setAppContactList ] = useState([]);
  const [ actualUser, setActualUser ] = useState({});
  const [ actualName, setActualName ] = useState({});
  const [ updaterId, setUpdaterId ] = useState(['']);
  const [ popup, setPopup ] = useState('popupHide');
  const [ popupVisibility, setPopupVisibility ] = useState(true);
  const [ popupMessage, setPopupMessage ] = useState('');
  const [ dataList, setDataList ] = useState([]);
  const [ jsonTextList, setJsonTextList ] = useState([]);
  const [ searchResultList, setSearchResultList ] = useState([]);
    
  useEffect(() => {
    loadContacts(ref)
    .then((value) => {
      setAppContactList([])
      setAppContactList(value)
    })
    .catch((error) => {
      console.log('app error', error)
    });

    dataRead()
    .then((value) => {
      fillDataList(value)
    })
    .catch((error) => {
      console.log('app error', error)
    });

  }, [actualUser, updaterId, searchResultList])


  const fillDataList = async (value) => { 
    
    let temp = [];
    {Object.keys(value).map(index =>{
      temp.push(value[index])
    })}
    setDataList(temp)
  }

  const callParser = async (name, link) => { 
    let res = await parser(name, link)
    return res
  }


  const anim1 = { hidden: { scale: 1, x: '200rem', y: '8.5rem' }, visible: { x: '10rem', y: '8.5rem', scale: 1,  transition: {type: "spring", stiffness: 70,  duration: 1 } }, hover: { scale: 0.75} }

  const popupHide = () => {
   setPopupVisibility(false)
   setTimeout(() => {
     setPopup('popupHide')
   }, 1000)
  }

  return (
    <BrowserRouter>
    
      <appContext.Provider value = {[
        appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
        popupMessage, setPopupMessage, dataList, jsonTextList, setJsonTextList, searchResultList, setSearchResultList 
        ]}>
        <div className='appContainer'>
          {popup === 'popupShow' ? 
            (<div className='popupContainer'>
              <AnimatePresence>
                {popupVisibility && (
                  <motion.div exit = { {x: 1400} }>
                    <motion.div variants = {anim1} initial = 'hidden' animate = 'visible'>
                      <Popup popupHide = {popupHide} ></Popup> 
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            )
            : ''
          }
          <Route path = "/" exact = {true} component = {HomeScreen}></Route>
          <Route path = "/home" component = {HomeScreen}></Route>
          <Route path = "/login"  component = {LoginScreen}></Route>
          <Route path = "/register" component = {RegisterScreen}></Route>
          <Route path = "/admin" component = {AdminScreen}></Route>
          <Route path = "/upload" component = {UploadScreen}></Route>
          <Route path = "/content" component = {ContentScreen}></Route>
          <Route path = "/search" component = {SearchScreen}></Route>

          <Route path='/showPdf' component={() => { window.open('https://homaly.herokuapp.com/pdf/loadPdf', '_blank')}}></Route>

        </div>
      </appContext.Provider>
    </BrowserRouter>
  );
  
}

export default App;
