import React, { useEffect, useState, useContext  } from 'react';
import { NavLink } from 'react-router-dom';
import { appContext } from '../App'
import '../App.css';
import fire from '../database/Firebase'


const Menu = (props) => {

  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
    popupMessage, setPopupMessage, dataList, jsonTextList, setJsonTextList, searchResultList, setSearchResultList
  ] = useContext(appContext)

  useEffect(() => {
   
  }, [actualUser])

  const handleMenu = (value) => { 
    if(Object.entries(actualUser).length === 0){
      console.log('A menüpont eléréséhez jelentkezzen be!')
      setPopupMessage('A menüpont eléréséhez jelentkezzen be!')
      setPopup('popupShow')
      setPopupVisibility(true)
      if(value === 'KIJELENTKEZÉS'){
        handleLogout()
      }
    }else if(actualUser.role === 'Jóváhagyott' || actualUser.role === 'admin'){
      switch(value){
        case 'KEZDŐLAP' : {
          props.history.push('content'); 
          break
        }
        case 'KERESÉS' : {
          props.history.push('search'); 
          break
        }
        case 'FELTÖLTÉS' : {
          props.history.push('upload'); 
          break
        }
        case 'KIJELENTKEZÉS' : {
          handleLogout()
          break
        }
      }
    }else{
      setPopupMessage('A regisztrációja még nincs jóváhagyva! A jóváhagyásról e-mailt fog kapni.')
      setPopup('popupShow')
      setPopupVisibility(true)
      if(value === 'KIJELENTKEZÉS'){
        handleLogout()
      }
    }
  }

  const handleLogout = (value) => {
    fire.auth().signOut().then(function() {
    }, 
    function(error) {
      console.error('Sign Out Error', error);
    });
    setActualUser('')
    setActualName('')
    const { history } = props;
    history.push('home');
  }


    return(
        <div className = 'menuContainer' >
          {actualName.length > 0 ? <p className="menuUser">Bejelentkezve:</p> : ''}
          {actualName.length > 0 ? <p className="menuUser">{actualName}</p> : ''}
          <p onClick={(e) => handleMenu('KEZDŐLAP')} className="NavLink">KEZDŐLAP</p>
          <p onClick={(e) => handleMenu('KERESÉS')} className="NavLink">KERESÉS</p>
          <p onClick={(e) => handleMenu('FELTÖLTÉS')} className="NavLink">FELTÖLTÉS</p>
          <p onClick={(e) => handleMenu('KIJELENTKEZÉS')} className="NavLink">KIJELENTKEZÉS</p>
        </div>


    )
}

export default Menu