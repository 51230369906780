import React, { useEffect, useState, useContext  } from 'react';
import { NavLink } from 'react-router-dom';
import { appContext } from '../App'
import '../App.css';
import fire, { auth } from '../database/Firebase'
import login from '../assets/login.jpg'
import { loadContacts, findContact } from '../database/DatabaseOp'
import Menu from '../components/Menu'

let ref = fire.database().ref('contacts')
let role = ''

const LoginScreen = (props) => {

  const { history } = props

  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup
  ] = useContext(appContext)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  
  const clearErrors = () => {
    setEmailError('')
    setPasswordError('')
  }

  const clearInputs = () => {
    setEmail('')
    setPassword('')
  }

  const setAct = (loginUser) => {
    fire.auth().onAuthStateChanged((user) => {
      if(user){
        clearInputs()
        findContact(ref, loginUser)
          .then((value) => {
            if(value == null){
              console.log('user NOT found ', loginUser)
            }else{
              //a value egy jsonObject, ennek a keys-edik indexén lévő value-t keresem     
              var keys = Object.keys(value);
              setActualUser(value[keys])
              setActualName(value[keys].name)
              role = value[keys].role

              //Admin jogosultság ellenőrzése
              if(role == 'admin'){
                const { history } = props;
                history.push('admin');
              }else{
                const { history } = props;
                history.push('content');
              }
            }
          })
          .catch((error) => {
            console.log('firstSearch error ', error)
          });
      }else{
        
        setActualUser('')
      }
    })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    clearErrors()
    let loginUser = {}
      loginUser.email = email

    fire
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((value) => {
      //a usernek az adatbázisban vannak paraméterei, ki kell keresni és beállítani actualusernek, Admin jogosultság ellenőrzése
      setAct(loginUser)
      })
      .catch((err) => {
        console.log('login error ', err)
        switch(err.code){
          case 'auht/invalid-email' : 
          case 'auth/user-disabled' :
          case 'auth/user-not-found' :
            setEmailError('Nem találjuk a fiókját... regisztrált már?')
            break
          case 'auth/wrong-password' :
            setPasswordError('Nem megfelelő jelszó')
            break
        }
      })   
  }

    return(
      <div className = 'appContainer'>
        <div className ='loginForm'>
          <form onSubmit={handleLogin} >
          <ul className="form-container">
            <li>
              <h2>Jelentkezzen be!</h2>
            </li>
            <li>
              <label htmlFor="email">
              Email
              </label>
              <input type="email" name="email" id="email" required onChange={(e) => setEmail(e.target.value)}></input>
              <p className = 'errorMsg'>{emailError}</p>
            </li>
            <li>
              <label htmlFor="password">Password</label>
              <input type="password" id="password" name="password" required onChange={(e) => setPassword(e.target.value)}></input>
              <p className = 'errorMsg'>{passwordError}</p>
            </li>
            <li>
              <button type="submit" className="button primary">Bejelentkezés</button>
            </li>
            <li>
              Még nincs fiókja?
            </li>
            <li>
              <NavLink to= "register"  className="button secondary text-center" >Regisztráljon!</NavLink>
            </li>
          </ul>
          </form>
        </div>
        <div className = 'menuContainer' >
          <Menu history = {history}></Menu>
        </div>
        <div className = 'imageContainer' >
          <img className = 'image'  src={login} alt="home1"></img>
        </div>
      </div>
    )
}

export default LoginScreen