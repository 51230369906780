import React, { useEffect, useState, useContext, Fragment  } from 'react';
import uploadPic from '../assets/upload.jpg'
import { NavLink } from 'react-router-dom';
import fire from '../database/Firebase'
import '../App.css';
import { appContext } from '../App'
import Uploader from '../components/Uploader'
import Menu from '../components/Menu'



function UploadScreen(props){

  const { history } = props

  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
        popupMessage, setPopupMessage, pdfList, setPdfList
  ] = useContext(appContext)

  useEffect(() => {

  }, [])

  return(
      <div className = 'uploadContainer'>
        <div className = 'menuContainer' >
          <Menu history = {history}></Menu>
        </div>
        <div className = 'searchContainer' >
          <Uploader history = {history}></Uploader>
        </div>
        <div className = 'imageContainer' >
          <img className = 'image'  src={uploadPic} alt="home1"></img>
        </div>
      </div>
    )
}

export default UploadScreen