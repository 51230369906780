import React from 'react';
import axios from 'axios'
import Uploader from '../components/Uploader';



export function loadContacts(ref) {
  return new Promise((resolve, reject) => {
    const onError = error => reject(error);
    const onData = snap => resolve(snap.val());

    ref.on("value", onData, onError);
  });
};

export const saveContact = async (ref, user) => {
  const response = await ref.push(user);

  return response
};

export function updateContact(ref, data) {
  return new Promise((resolve, reject) => {
    const onError = error => reject(error);
    const onData = snap => resolve(data);
    ref.update(data);
  });
};


export function findContact(ref, user) {
  return new Promise((resolve, reject) => {
    const onError = error => reject(error);
    const onData = snap => resolve(snap.val());

    var query = ref.orderByChild('email').equalTo(user.email); // the var email is the email you're looking for
    query.on('value', onData, onError)
  });
};

export function findContactByEmail(ref, email) {
  return new Promise((resolve, reject) => {
    const onError = error => reject(error);
    const onData = snap => resolve(snap.val());

    var query = ref.orderByChild('email').equalTo(email); // the var email is the email you're looking for
    query.on('value', onData, onError)
  });
};

export const dataRead = async (url) => {
  const response = await fetch('https://homalyzona.herokuapp.com/jphdata/getjphdata')
  if(response.status !== 200){
    throw new Error('Fetch error')
  }
  const data = await response.json()
  return data
};

export const jsonRead = async (url) => {
  const response = await fetch('https://homalyzona.herokuapp.com/jphdata/getjsonlist')
  if(response.status !== 200){
    throw new Error('Fetch error')
  }
  const data = await response.json()
  return data
};

export const pdfSave = async (name, url) => {
  axios.post('https://homaly.herokuapp.com/pdf/insert', {
    name : name,
    link : url,
  })
  .then(response => {
    console.log('PDF to HEROKU')
    return response
  })
  .catch( error => {
    console.log(error);
    return error
  });

  
};

export const jsonSave = async (name, url) => {
  axios.post('https://homaly.herokuapp.com/json/insert', {
    name : name,
    link : url,
  })
  .then(response => {
    console.log('JSON to HEROKU')
    return response
  })
  .catch( error => {
    console.log(error);
    return error
  });

  
};

export const jsonParse = async (url) => {
  console.log('databaseop jsonparse url ', url)
  let theUrl = 'https://homaly.herokuapp.com/json/parsejson?url=' + url
  console.log('databaseop jsonparse theurl ', theUrl)
  const response = await fetch(theUrl)
    if(response.status !== 200){
      throw new Error('Fetch error')
    }
    const data = await response.json()
  
    return data

  
};


export const textReadAll = async () => {
  const response = await fetch('https://homaly.herokuapp.com/pdf/findAll')
  if(response.status !== 200){
    throw new Error('Fetch error')
  }
  const data = await response.json()

  return data
};




function DatabaseOp(props) {

  
 
  return (
    <div >

    </div>
    );

  }


export default DatabaseOp;
