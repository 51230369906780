import React, { useEffect, useState, useContext, Fragment  } from 'react';
import '../App.css';
import { appContext } from '../App'
import ReactS3 from 'react-s3'
import { pdfSave, dataRead } from '../database/DatabaseOp'
require("dotenv").config()

function Uploader(props){


  
  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, popup, setPopup, popupVisibility, setPopupVisibility,
        popupMessage, setPopupMessage, pdfList, setPdfList
  ] = useContext(appContext)

  const [ file, setFile ] = useState(null)
  const [ fileName, setFileName ] = useState(null)

  const s3Config = {
    bucketName: process.env.REACT_APP_PDFBUCKETNAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  }

  useEffect(() => {

  }, [])

    const handleChangeUpload = (e) => { 
    setFile(e.target.files[0])
    setFileName(e.target.files[0].name) 
  }
  
    const handleUpload = (e) => {
      e.preventDefault()
       ReactS3.uploadFile(file, s3Config)
      .then((data) =>{
        setPopupMessage(`A ${data.key} nevű file feltöltve!`)
        setPopup('popupShow')
        setPopupVisibility(true)
        savePdf(data.key, data.location)
      })
      .catch((err) => {
        console.log('handleupload catch', err)
      })
    }

      //feltöltött file adatai az adatbázisba
      const savePdf = (name, url) => {
        pdfSave(name, url)
        .then(data => 
          console.log('upload resolved '),
          
          dataRead()
            .then((value) => {
              setPdfList(value)
            }).catch((error) => {
              console.log('app error', error)
            })

        ).catch(err => console.log('upload rejected ', err))
      }

    const handleShow = (e) => { 
      props.history.push('showPdf');
    }
  
    return(
      <div className = 'appContainer'>
        <div className = 'pdf'>
          <div className = 'pdfUpload'>
            <h3>PDF feltöltés</h3>
            <div className = 'pdfUpload'>
              <Fragment>
              <div className="input-group mb-5">
                <div className="custom-file">
                  <input type="file" class="custom-file-input" id="inputGroupFile02" onChange = {handleChangeUpload} ></input>
                    <label className="custom-file-label" htmlFor="inputGroupFile02">{fileName}</label>
                </div>
                <div className="input-group-append">
                  <span onClick={(e) => handleUpload(e)} class="input-group-text" id="">Upload</span>
                </div>
              </div>
              
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Uploader